export const BOT_HISTORY = "botHistory";

export const CONNECTION_ESTABLISHED = {
  id: "001",
  type: "Shoppi-Connection-Established",
};

export const OPEN_BUTTON_CLICKED = {
  id: "002",
  type: "Shoppi-Maximize-Button-Clicked",
};

export const CLOSED_BUTTON_CLICKED = {
  id: "003",
  type: "Shoppi-Minimize-Button-Clicked",
};

export const REDIRECT = { id: "004", type: "Shoppi-Product-Clicked" };

export const ADD_TO_CART = {
  id: "005",
  type: "Shoppi-Add-To-Cart-Clicked",
};

// client events
export const CLIENT_INIT_DATA = {
  id: "100",
  type: "Client-Init-Data",
};

export const CLIENT_PRODUCT_NAVIGATION = {
  id: "101",
  type: "Client-Product-Navigation",
};
