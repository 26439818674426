import React, { useState, useEffect, useRef } from "react";
import ImageList from "./ImageList";
import chatLogo from "../assets/images/logo_cropped.png";
import UserForm from "./UserForm";
import { useVerification } from "../context/VerificationContext";
import Loader from "./Loader";

function Modal(props) {
  const {
    messages,
    question,
    isGeneratingChat,
    isChatReady,
    sendChatReq,
    clearChatHistory,
    setContext,
    OGquestion,
    setOGquestion,
    removeContext,
    isContextSet,
    prodContext,
    handleDockButtonClose,
    add2cart,
    toggleChatBox,
    userProfile,
    setUserProfile,
    serverError,
  } = props;

  const chatContainerRef = useRef(null);
  const { verified, errorMessage, verifyUser, clientId, botConfig, isLoading } =
    useVerification();

  const scrollToBottom = () => {
    if (chatContainerRef.current?.scrollTop) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    }
  };

  const isProdContext =
    !prodContext.text || prodContext.text === "none" ? "" : prodContext.text;

  const {
    font_color_bot: fontColorBot,
    font_colot_user: fontColorUser,
    font_szie: fontSize,
    font_family: fontFamily,
    icon_size_height: iconSizeHeight,
    icon_size_width: iconSizeWidth,
    icon_shape: iconShape,
    bot_styles: {
      message_style: { bot_message_bg_color, user_message_bg_color } = {},
      font_style: {
        font_color_bot,
        font_colot_user,
        font_szie,
        font_family,
      } = {},
      icon_style: { icon_size_height, icon_size_width, icon_shape } = {},
      header: headerColor,
    } = {},
    startUpQuestion,
    pdpQuestion,
  } = botConfig;

  const defaultQuestion = [
    "Can you help me find a new pair of jeans?",
    "Im going to a formal dinner, got anything?",
    "What types of dresses do you carry?",
    "It's gonna rain soon, got any jackets for me?",
    "Do you guys have anything for warm weather?",
  ];

  const startUpQuestionArray =
    startUpQuestion?.length > 0
      ? messages.length == 0 && isProdContext && pdpQuestion?.length > 0
        ? pdpQuestion
        : startUpQuestion
      : defaultQuestion;

  useEffect(() => {
    scrollToBottom();
  }, [messages, isGeneratingChat, toggleChatBox]);

  return (
    <div
      id="modal"
      style={{
        display: toggleChatBox,
        backgroundColor: "white",
        padding: "17px 30px",
      }}
    >
      <div
        className="parent"
        style={{
          backgroundColor: headerColor ?? "white",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            float: "left",
            textDecoration: "none",
            fontSize: "18.5px",
            color: "rgb(29, 29, 29)",
            fontWeight: "bold",
          }}
          type="button"
          onClick={handleDockButtonClose}
        >
          <i
            className="fa-solid fa-chevron-down"
            style={{ color: "rgb(29, 29, 29)" }}
          ></i>
        </span>
        <span className="middle">
          <img
            src={chatLogo}
            width="36.5px"
            height="38.5px"
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              width: "36.5px",
              height: "38.5px",
            }}
            alt="Home"
          />
        </span>

        <span
          style={{
            float: "right",
            textDecoration: "none",
            marginRight: "inherit",
          }}
          type="button"
        >
          <button
            onClick={clearChatHistory}
            disabled={isGeneratingChat}
            type="button"
            className="btn btn-light"
            style={{
              width: "50px",
              height: "47.5px",
              borderRadius: "50%",
            }}
          >
            <i
              className="fa-solid fa-rotate-right"
              style={{
                color: "#5c5c5c",
                fontSize: "20px",
                textAlign: "center",
                paddingTop: "2.5px",
              }}
            ></i>
          </button>
        </span>
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "60vh",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div>
            <Loader />
            <button
              style={{
                marginTop: "75px",
                border: "none",
                background: "none",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={handleDockButtonClose}
            >
              Close
            </button>
          </div>
        </div>
      ) : verified ? (
        Object.keys(userProfile).length == 0 ? (
          <UserForm setUserProfile={setUserProfile} />
        ) : (
          <>
            <div
              id="modalCardContainer"
              style={{
                paddingTop: "10px",
                marginTop: "5px",
                height: "calc(100vh - 135px)",
                backgroundColor: "white",
              }}
              ref={chatContainerRef}
            >
              <div id="Segment">
                <div
                  id="answerSegment"
                  style={{ margin: "0px", paddingBottom: "0px" }}
                >
                  <div className="imessage" style={{ width: "90%" }}>
                    <p
                      className="from-them"
                      style={{
                        backgroundColor:
                          bot_message_bg_color ?? "rgb(249, 244, 241)",
                        color: font_color_bot ?? "#000",
                        fontSize: font_szie ?? "16px",
                        fontFamily: font_family ?? "Arial",
                      }}
                    >
                      {messages.length === 0 && isProdContext
                        ? `Hi, I am your shopping assistant! Ask me anything about the ${isProdContext}`
                        : "Hi, I am your shopping assistant! How can I help you?"}
                    </p>
                  </div>
                </div>

                <div
                  id="answerSegment"
                  style={{ margin: 0, padding: 0, float: "left" }}
                  hidden={isGeneratingChat || messages.length > 0}
                >
                  <div className="imessage" style={{ minWidth: "100%" }}>
                    <div
                      className="from-me"
                      style={{
                        minWidth: "92.5%",
                        backgroundColor:
                          user_message_bg_color ?? "rgb(22, 51, 210)",
                        color: font_colot_user ?? "rgb(255, 255, 255)",
                        fontSize: font_szie ?? "16px",
                        fontFamily: font_family ?? "Arial",
                      }}
                    >
                      {startUpQuestionArray.map((el, i) => (
                        <>
                          <div
                            key={i}
                            type="button"
                            className="startUpQuestion"
                            style={{
                              width: "100%",
                              borderRadius: 0,
                              fontFamily: font_family ?? "Arial",
                              padding: `${
                                i == 0 ? "5px 0 0 5px" : "20px 0 0 5px"
                              }`,
                              height: `${i == 0 ? "35px" : "50px"}`,
                              borderBottom: `${
                                i == startUpQuestionArray.length - 1
                                  ? "0px solid"
                                  : "1px solid "
                              }`,
                            }}
                            id="options"
                            onClick={() => sendChatReq(`${el} `)}
                          >
                            {el}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>

                <div>
                  {messages.map((message, index) => (
                    <div key={index}>
                      <div
                        id="questionSegment"
                        style={{ margin: 0, paddingBottom: 0 }}
                      >
                        <div className="imessage">
                          <p
                            className="from-me"
                            style={{
                              backgroundColor:
                                user_message_bg_color ?? "rgb(22, 51, 210)",
                              color: font_colot_user ?? "rgb(255, 255, 255)",
                              fontSize: font_szie ?? "16px",
                              fontFamily: font_family ?? "Arial",
                            }}
                          >
                            {isProdContext && (
                              <span
                                style={{
                                  backgroundColor: "rgba(255, 185, 0, 0.7)",
                                  fontFamily: font_family ?? "Arial",
                                }}
                              >
                                {isProdContext}
                              </span>
                            )}
                            {message.question}
                          </p>
                        </div>
                      </div>

                      <div
                        id="answerSegment"
                        style={{ margin: 0, paddingBottom: 0 }}
                      >
                        <div className="imessage">
                          <p
                            className="from-them"
                            style={{
                              backgroundColor:
                                bot_message_bg_color ?? "rgb(249, 244, 241)",
                              color: font_color_bot ?? "#000",
                              fontSize: font_szie ?? "16px",
                              fontFamily: font_family ?? "Arial",
                            }}
                          >
                            {message.answer}
                          </p>
                        </div>
                      </div>

                      <ImageList
                        message={message}
                        isGeneratingChat={isGeneratingChat}
                        sendChatReq={sendChatReq}
                        setContext={setContext}
                        prodContext={prodContext}
                        isContextSet={isContextSet}
                        add2cart={add2cart}
                        font_family={font_family}
                      />
                    </div>
                  ))}
                  <div
                    id="Segment"
                    style={{ display: isGeneratingChat ? "block" : "none" }}
                  >
                    {isGeneratingChat && (
                      <div
                        id="questionSegment"
                        style={{ margin: "0px", paddingBottom: "0px" }}
                      >
                        <div className="imessage">
                          <p
                            className="from-me"
                            style={{ display: isContextSet ? "block" : "none" }}
                          >
                            <span className="highlight-container">
                              <span
                                className="highlight"
                                id="titles"
                                style={{
                                  backgroundColor: "rgba(255, 185, 0, 0.7)",
                                  fontFamily: font_family ?? "Arial",
                                }}
                              >
                                {`${isProdContext}`}
                              </span>
                            </span>
                            <a style={{ width: "10px" }}></a> {question}
                          </p>
                          <p
                            className="from-me"
                            style={{
                              display: isContextSet ? "none" : "block",
                              backgroundColor:
                                user_message_bg_color ?? "rgb(22, 51, 210)",
                              color: font_colot_user ?? "rgb(255, 255, 255)",
                              fontSize: font_szie ?? "16px",
                              fontFamily: font_family ?? "Arial",
                            }}
                          >
                            {question}
                          </p>
                        </div>
                      </div>
                    )}
                    {isGeneratingChat && (
                      <div
                        id="answerSegment"
                        style={{ margin: "0px", paddingBottom: "0px" }}
                      >
                        <div
                          className="imessage"
                          style={{ width: "90%", padding: "0px" }}
                        >
                          <p className="from-them loading">
                            <span></span>
                            <span></span>
                            <span></span>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span
                style={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "#fff",
                }}
              >
                {!isContextSet && (
                  <input
                    disabled={!isChatReady}
                    id="demoE"
                    placeholder={
                      isProdContext
                        ? `Ask about ${isProdContext}`
                        : "Ask me anything"
                    }
                    type="text"
                    name="topic"
                    required
                    className="form-control"
                    value={OGquestion}
                    onChange={(e) => setOGquestion(e.target.value)}
                    autoCapitalize="sentences"
                    autoComplete="off"
                    autoFocus={true}
                    spellCheck={true}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sendChatReq(OGquestion);
                      }
                    }}
                    style={{
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      fontSize: font_szie ?? "16px",
                      fontFamily: font_family ?? "Arial",
                    }}
                    maxLength="200"
                  />
                )}

                {isContextSet && (
                  <input
                    disabled={!isChatReady}
                    id="demoE"
                    placeholder={`Type here ${isProdContext}`}
                    type="text"
                    name="topic"
                    required
                    className="form-control"
                    value={OGquestion}
                    onChange={(e) => setOGquestion(e.target.value)}
                    autoCapitalize="sentences"
                    autoComplete="off"
                    autoFocus={true}
                    spellCheck={true}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sendChatReq(OGquestion);
                      }
                    }}
                    style={{
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      fontFamily: font_family ?? "Arial",
                    }}
                    maxLength="200"
                  />
                )}

                <button
                  disabled={isGeneratingChat}
                  hidden={isContextSet !== true}
                  type="button"
                  className="btn btn-light"
                  onClick={removeContext}
                  id="askQuestionBox"
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    borderLeftColor: "rgb(255, 255, 255)",
                    borderRightColor: "rgb(255, 255, 255)",
                    paddingTop: "8px",
                  }}
                >
                  <i
                    className="fa-solid fa-right-from-bracket"
                    style={{ color: "rgb(20, 20, 20)", fontSize: "21px" }}
                  ></i>
                </button>

                <button
                  disabled={isGeneratingChat}
                  type="button"
                  className="btn btn-light"
                  onClick={() => sendChatReq(question)}
                  id="askQuestionBox"
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    borderBottomRightRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <i
                    style={{ fontSize: "16px" }}
                    className="fa-regular fa-paper-plane"
                  ></i>
                </button>
              </span>
            </div>{" "}
          </>
        )
      ) : (
        <div
          style={{
            width: "100%",
            height: "60vh",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            color: "red",
          }}
        >
          <p style={{ margin: 0 }}>{errorMessage}</p>
          <button
            style={{
              border: "none",
              background: "none",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => verifyUser(clientId)}
          >
            Verify
          </button>
          <button
            style={{
              border: "none",
              background: "none",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={handleDockButtonClose}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default Modal;
