import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const VerificationContext = createContext();

export const useVerification = () => {
  return useContext(VerificationContext);
};

export const VerificationProvider = ({ children }) => {
  const [verified, setVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clientId, setClientId] = useState("");
  const [botConfig, setBotConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const getClientData = (domain, clientIdFromIframe) => {
    try {
      const url = `${process.env.REACT_APP_INGESTOR_HOST}/api/v1/clients/${clientIdFromIframe}/configurations`;
      axios
        .get(url)
        .then(async (response) => {
          const domains = response.data.whitelist_domain;
          console.log(
            "domains?.includes(domain) ",
            domains?.includes(domain),
            domains
          );
          if (domains?.includes(domain)) {
            setBotConfig(response.data);
            setClientId(response.data.client_id);
            setVerified(true);
            setIsLoading(false);
          } else {
            setVerified(false);
            setErrorMessage("User not verified.");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setErrorMessage("Some Error Occured");
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const verifyUser = (clientIdFromIframe) => {
    try {
      const parentDomain = new URL(
        window.location != window.parent.location
          ? document.referrer
          : document.location.href
      ).hostname;
      const topDomain = new URL(
        window.location != window.top.location
          ? document.referrer
          : document.location.href
      ).hostname;
      console.log("topDomain ", topDomain);
      console.log("parentDomain ", parentDomain);
      if (topDomain === parentDomain) {
        console.log("called verify user");
        getClientData(topDomain, clientIdFromIframe);
      } else {
        setVerified(false);
        setErrorMessage("User not verified.");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Error verifying user.");
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const getClientIdFromIframe = urlParams.get("clientid");
    console.log("clientIdFromIframe ", getClientIdFromIframe);
    verifyUser(getClientIdFromIframe);
  }, []);

  console.log(document.location.search);
  return (
    <VerificationContext.Provider
      value={{
        verified,
        errorMessage,
        verifyUser,
        clientId,
        botConfig,
        isLoading,
      }}
    >
      {clientId && children}
    </VerificationContext.Provider>
  );
};
