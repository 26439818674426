import React, { useState, useEffect } from "react";

import Modal from "./Modal";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import chatLogo from "../assets/images/logo_cropped_sq.png";
import Logo from "../assets/images/chatLogo2.png";

import "../styles/ChatbotForm.css";
import "../styles/custom.css";
import "../styles/main_styles.css";
import "../styles/responsive.css";
import "../styles/ToggleButton.css";
import {
  BOT_HISTORY,
  CLOSED_BUTTON_CLICKED,
  CONNECTION_ESTABLISHED,
  OPEN_BUTTON_CLICKED,
  CLIENT_INIT_DATA,
} from "../constants";
import { PayloadModel } from "../models/payload";
import { useVerification } from "../context/VerificationContext";

const ChatComponent = () => {
  const { verified, errorMessage, verifyUser, botConfig, clientId, isLoading } =
    useVerification();
  const [isChatReady, setIsChatReady] = useState(false);
  const [ws, setWs] = useState(false);
  const [messages, setMessages] = useState(() => {
    try {
      const storedHistory = localStorage.getItem(BOT_HISTORY);
      return JSON.parse(storedHistory) || [];
    } catch (error) {
      console.error("Error parsing bot history from localStorage:", error);
      return [];
    }
  });
  const [question, setQuestion] = useState("");
  const [OGquestion, setOGquestion] = useState("");
  const [isGeneratingChat, setIsGeneratingChat] = useState(false);
  const [prodContext, setProdContext] = useState(() => {
    try {
      const spotlightData = localStorage.getItem("spotlight");
      return JSON.parse(spotlightData) || { id: "", text: "none" };
    } catch (error) {
      console.error("Error parsing spotlight data from localStorage:", error);
      return { id: "", text: "none" };
    }
  });
  const [isContextSet, setIsContextSet] = useState(() =>
    prodContext.text && prodContext.text !== "none" ? true : false
  );
  const [prodContextNoPrice, setProdContextNoPrice] = useState("none");
  const [toggleChatBox, setToggleChatBox] = useState({
    hideChatBox: "none",
    hideToggleButton: "flex",
  });
  const [userProfile, setUserProfile] = useState(() => {
    try {
      const userProfile = localStorage.getItem("userProfile");
      return JSON.parse(userProfile) || {};
    } catch (error) {
      console.error("Error parsing User Profile from localStorage:", error);
      return {};
    }
  });
  const [pdpName, setPdpName] = useState("");
  const [pdpPrice, setPdpPrice] = useState("");
  const atD = " costing $";

  let origin = window.self === window.top ? document.URL : document.referrer;
  origin = origin.substring(0, origin.length - 1);

  function establishWebSocketConnection() {
    console.log("connection called ");
    let host = process.env.REACT_APP_CURATOR_HOST;
    if (clientId == "hamptonsun_ffa6a973-aa8b-4e25-8d2e-992ad1811f31") {
      host = "hamptonsuncurator.shoppi.ai";
      console.log("host name in if", host);
    } else if (clientId == "demo_sechey_9ac908cf-aeb9-4585-8f94-ffee9c9e53cf") {
      host = "secheycurator.shoppi.ai";
    }
     console.log("host name ", host);
    const ws = new WebSocket(
      `${process.env.REACT_APP_WS_PROTOCOL}://${host}/client/${clientId}/messageForHome`
    );
    setWs(ws);

    ws.onopen = () => {
      console.log("WebSocket connection established");
      setIsChatReady(true);
      window.parent.postMessage(
        {
          id: CONNECTION_ESTABLISHED.id,
          type: CONNECTION_ESTABLISHED.type,
          shoppiData: {
            product: {},
            hrefData: {},
            review: {},
            search: {},
          },
        },
        origin
      );
      console.log("CALLED IN AFTER CONNECTION ESTABLISHED");
      window.addEventListener("message", handleIframeEvent);
    };

    ws.onerror = (error) => {
      console.log(`WebSocket error: `, error);
      if (ws && ws.readyState === WebSocket.OPEN) {
        establishWebSocketConnection();
      }
    };

    ws.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `WebSocket connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        console.log("WebSocket connection closed unexpectedly");
      }
    };

    ws.onmessage = (event) => {
      console.log(`Received message: ${event.data}`);
    };
  }

  const handleIframeEvent = (event) => {
    const chatInitialisationData = event.data.data?.user;

    const eventRecevied = event.data?.type;
    const eventPdpData = event.data.data?.product;
    if (eventRecevied == CLIENT_INIT_DATA.type) {
      if (Object.keys(userProfile).length !== 0) {
        if (
          userProfile.email !== chatInitialisationData.email ||
          userProfile.name !== chatInitialisationData.name
        ) {
          setUserProfile(chatInitialisationData);
        }
      }
      setPdpName(eventPdpData.name);
      setPdpPrice(eventPdpData.price);
      localStorage.setItem(
        "spotlight",
        JSON.stringify({ id: eventPdpData.id, text: eventPdpData.name })
      );
      setProdContext({
        id: eventPdpData.id,
        text: eventPdpData.name,
      });
      setIsContextSet(true);
      setProdContextNoPrice("none");
    }
  };

  useEffect(() => {
    establishWebSocketConnection();

    return () => {
      ws.close();
    };
  }, [clientId]);

  useEffect(() => {
    try {
      localStorage.setItem(BOT_HISTORY, JSON.stringify(messages));
    } catch (error) {
      console.error("Error saving bot history to localStorage:", error);
    }

    try {
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
    } catch (error) {
      console.error("Error saving UserProfile to localStorage:", error);
    }
  }, [messages, userProfile]);

  const saveChatHistory = () => {
    try {
      localStorage.setItem(BOT_HISTORY, JSON.stringify(messages));
    } catch (error) {
      console.error("Error saving bot history to localStorage:", error);
    }
  };

  const clearChatHistory = () => {
    // removeContext();
    let host = process.env.REACT_APP_CURATOR_HOST;
    if (clientId == "hamptonsun_ffa6a973-aa8b-4e25-8d2e-992ad1811f31") {
      host = "hamptonsuncurator.shoppi.ai";
    } else if (clientId == "demo_sechey_9ac908cf-aeb9-4585-8f94-ffee9c9e53cf") {
      host = "secheycurator.shoppi.ai";
    }
    ws.close();
    const newWs = new WebSocket(
      `${process.env.REACT_APP_WS_PROTOCOL}://${host}/client/${clientId}/messageForHome`
    );

    setWs(newWs);

    setMessages([]);
    setQuestion("");
    setIsGeneratingChat(false);
    try {
      localStorage.removeItem(BOT_HISTORY);
    } catch (error) {
      console.error("Error removing item from localStorage:", error);
    }
  };

  const sendChatReq = (userQuery) => {
    setQuestion(userQuery);
    setOGquestion("");
  };

  useEffect(() => {
    let payload = {};
    if (question.trim().length > 1 && question.trim().length < 200) {
      if (isContextSet === true) {
        const payloadText = prodContext.text.includes("costing")
          ? prodContext.text
          : prodContext.text + atD + pdpPrice;

        payload = {
          question: `This is my question about the ${payloadText}: ${question}`,
          isContextSet: isContextSet,
          prodContext: payloadText,
          chatHistory: messages,
          userProfile: userProfile,
        };
      } else {
        setProdContext({ id: "", text: "none" });
        setIsContextSet(false);
        setProdContextNoPrice("none");

        payload = {
          question: question,
          isContextSet: isContextSet,
          prodContext: prodContext.text,
          chatHistory: messages,
          userProfile: userProfile,
        };
      }
      setIsGeneratingChat(true);

      ws.send(JSON.stringify(payload));

      const taskToDoAfterResponse = () => {
        setQuestion("");
        setOGquestion("");
        setIsGeneratingChat(false);

        ws.removeEventListener("message", onMessage);
        saveChatHistory();
      };
      const onMessage = (event) => {
        const response = JSON.parse(event.data).result;
        if (response === "Server down, please try again later") {
          if (ws && ws.readyState === WebSocket.OPEN) {
            console.log("Server is down. Reconnecting...");

            setMessages((prevMessages) => [
              ...prevMessages,
              {
                question: question,
                answer: "Server is down. Reconnecting...",
                products: [],
                context: prodContextNoPrice,
              },
            ]);
            taskToDoAfterResponse();
            // check if close  ? reconnect
            establishWebSocketConnection();
          }

          return;
        }

        const prodsList = response["result"][1];

        setMessages((prevMessages) => [
          ...prevMessages,
          {
            question: question,
            answer: String(response.result[0]),
            products: prodsList,
            context: prodContextNoPrice,
          },
        ]);

        taskToDoAfterResponse();

        ws.removeEventListener("message", onMessage);
        saveChatHistory();
      };

      ws.addEventListener("message", onMessage);

      return () => {
        ws.removeEventListener("message", onMessage);
      };
    }
  }, [question]);

  const setContext = (prodText, atD, prodPrice) => {
    setIsContextSet(true);

    let prod = { id: "", text: "none" };
    const regex = /\$[0-9]+/;

    let allProducts = messages?.reduce((prods, msg) => {
      if (Array.isArray(msg.products))
        msg.products.forEach((prod) => prods.push(prod));
      return prods;
    }, []);
    prod = allProducts.find((prod) => prod.text === prodText);
    let payloadText = prod?.text + atD + prodPrice;

    setProdContext({ id: "", text: prod?.text });
    localStorage.setItem(
      "spotlight",
      JSON.stringify({ id: "", text: prod?.text })
    );
    setProdContextNoPrice(
      payloadText.replace(regex, "").replace("costing", "").trim()
    );
  };

  const removeContext = () => {
    setIsContextSet(false);
    setProdContext({ id: "", text: "none" });
    localStorage.setItem("spotlight", JSON.stringify({ id: "", text: "none" }));
  };

  const handleDockButtonClose = () => {
    setToggleChatBox({ hideChatBox: "none", hideToggleButton: "flex" });
    window.parent.postMessage(
      {
        id: CLOSED_BUTTON_CLICKED.id,
        type: CLOSED_BUTTON_CLICKED.type,
        shoppiData: {
          product: {},
          hrefData: {},
          review: {},
          search: {},
        },
      },
      origin
    );
  };

  const handleDockButtonOpen = () => {
    setToggleChatBox({
      hideChatBox: "block",
      hideToggleButton: "none",
    });
    window.parent.postMessage(
      {
        id: OPEN_BUTTON_CLICKED.id,
        type: OPEN_BUTTON_CLICKED.type,
        shoppiData: {
          product: {},
          hrefData: {},
          review: {},
          search: {},
        },
      },
      origin
    );
  };
  const add2cart = (prod) => {
    alert(`The ${prod} have been added to your cart!`);
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Modal
        messages={messages}
        question={question}
        isGeneratingChat={isGeneratingChat}
        isChatReady={isChatReady}
        sendChatReq={sendChatReq}
        clearChatHistory={clearChatHistory}
        setQuestion={setQuestion}
        setContext={setContext}
        OGquestion={OGquestion}
        setOGquestion={setOGquestion}
        removeContext={removeContext}
        isContextSet={isContextSet}
        prodContext={prodContext}
        handleDockButtonClose={handleDockButtonClose}
        add2cart={add2cart}
        toggleChatBox={toggleChatBox.hideChatBox}
        userProfile={userProfile}
        setUserProfile={setUserProfile}
        setMessages={setMessages}
        botConfig={botConfig}
        pdpName={pdpName}
      />
      <div
        id="toggleButtons"
        style={{
          display: toggleChatBox.hideToggleButton,
          width:
            botConfig?.bot_styles?.icon_style.icon_size_width - 20 ?? "40px",
          height:
            botConfig?.bot_styles?.icon_style.icon_size_height - 20 ?? "40px",
          borderRadius:
            botConfig?.bot_styles?.icon_style.icon_shape == "Round"
              ? "50%"
              : "10px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          boxShadow: "none",
          cursor: "auto",
        }}
        onClick={handleDockButtonOpen}
      >
        <img
          src={botConfig?.logo ?? Logo}
          alt="Toggle Button"
          style={{
            cursor: "pointer",
            height:
              botConfig?.bot_styles?.icon_style.icon_size_height ?? "100px",
            width: botConfig?.bot_styles?.icon_style.icon_size_width ?? "100px",
            borderRadius:
              botConfig?.bot_styles?.icon_style.icon_shape == "Round"
                ? "50%"
                : "10px",
          }}
        />
      </div>
    </div>
  );
};
export default ChatComponent;
