import React, { useState } from "react";
import "../styles/userForm.css";

const UserForm = ({ setUserProfile }) => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    comment: "",
  });

  const [formErrors, setFormErrors] = useState({
    emailError: "",
    nameError: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const regex = /<\/?[^>]+(>|$)/g;

    if (name === "name" || name === "comment") {
      if (value.match(regex)) {
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!formData.email) {
      errors.emailError = "Email is required";
      valid = false;
    } else if (!isValidEmail(formData.email)) {
      errors.emailError = "Enter a valid email";
      valid = false;
    }

    if (!formData.name) {
      errors.nameError = "Name is required";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      console.log(formData);
      setUserProfile({
        email: formData.email,
        name: formData.name,
        comment: formData.comment,
      });
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
          {formErrors.emailError && (
            <p className="error-message">{formErrors.emailError}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
          />
          {formErrors.nameError && (
            <p className="error-message">{formErrors.nameError}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="comment">Comment</label>
          <textarea
            id="comment"
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            placeholder="What brings you here?"
          />
        </div>
        <button className="submit-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default UserForm;
