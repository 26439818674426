import React from "react";

import pinterestLogo from "../assets/images/pinterest-circle-logo-png-transparent.png";
import twitterLogo from "../assets/images/x-logo-twitter.webp";
import facebookLogo from "../assets/images/Facebook-logo-blue-circle-large-transparent-png.png";
import emailLogo from "../assets/images/529-5294776_email-icon-black-clipart-computer-icons-email-mail-removebg-preview.png";
import copyLogo from "../assets/images/Copy_image_green.png";

const host = process.env.REACT_APP_CURATOR_HOST + '/client/demo';

function SocialMedia(props) {

    const {isGeneratingChat, pair} = props

    const getPinterest = (name, img) => {
        const description = name;
        const media = `${img}&format=jpeg`;
        const method = "button";
        const url = `${host}/${name}?image=${media}`;
        const baseUrl = "https://www.pinterest.com";
        const path = "/pin-builder/";
        const pinterestUrl = `${baseUrl}${path}?description=${encodeURIComponent(description)}&media=${encodeURIComponent(media)}&method=${method}&url=${encodeURIComponent(url)}`;
        window.open(pinterestUrl, '_blank');
      };
    
      const getTwitter = (name, img) => {
        const url = `yourStore.com/${name.replace(/ /g, '-')}`;
        window
        .open(`http://twitter.com/intent/tweet?text=Check%20out%20the%20${name}!&via=YourStore&url=${url}`, '_blank')
        .focus();
    
      };
    
      const getFacebook = (name, img) => {
        const url = `${host}/${name}?image=${img}`;
        window
        .open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}&hashtag=%23YourStoreName`,
          '_blank'
        )
        .focus();
      };
    
      const getEmail = (name, img) => {
        const url = `yourStore.com/${name.replace(/ /g, '-')}`;
        const subject = `The ${name}`;
        const body = `Check out the ${name} on (Your store name) here!\n${url}`;
    
        const gmailLink = `https://mail.google.com/mail/u/0/?fs=1&tf=cm&su=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`;
        window.open(gmailLink, '_blank');
      
      };
    
    
      const copyLink = (name, img) => {
        const url = `${host}/${name}?image=${img}`;
        navigator.clipboard.writeText(url)
          .then(() => {
            alert('Link Copied');
          })
          .catch((error) => {
            console.error('Failed to copy link:', error);
          });
      };

    return (
        <div>
        <button
          style={{
            background: 'transparent',
            border: 'none',
            fontSize: 'var(--grouped-icon-font-size)',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          disabled={isGeneratingChat}
          className="buy_button"
        >
          <span type="button">
            <i
              style={{ color: 'rgb(0, 0, 0)' }}
              className="fa-solid fa-arrow-up-from-bracket"
            ></i>
          </span>
        </button>


        <div className="dropdown-menu dropdown-menu-right" style={{ position: 'absolute', top: 0, left: 0 }}>
          <h6 className="dropdown-header" style={{ fontFamily: 'Inter, sans-serif', fontSize: '13px' }}>
            Share
          </h6>

          <button
            className="dropdown-item"
            type="button"
            style={{
              color: 'black',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14.5px',
              paddingLeft: '10px',
            }}
            onClick={() => getPinterest(pair.text, pair.url)}
            disabled={isGeneratingChat}
          >
            <img
              src={pinterestLogo}
              height="28px"
              width="32px"
              style={{ paddingRight: '4px' }}
              alt=""
            />
            Pinterest
          </button>
          <button
            className="dropdown-item"
            type="button"
            style={{
              color: 'black',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14.5px',
              paddingLeft: '10px',
            }}
            onClick={() => getTwitter(pair.text, pair.url)}
            disabled={isGeneratingChat}
          >
            <img
              src={twitterLogo}
              height="28px"
              width="32px"
              style={{ paddingRight: '4px' }}
              alt=""
            />
            Twitter
          </button>

          <button
            className="dropdown-item"
            type="button"
            style={{
              color: 'black',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14.5px',
              paddingLeft: '10px',
            }}
            onClick={() => getFacebook(pair.text, pair.url)}
            disabled={isGeneratingChat}
          >
            <img
              src={facebookLogo}
              height="26px"
              width="30px"
              style={{ paddingRight: '4px' }}
              alt=""
            />
            Facebook
          </button>

          <button
            className="dropdown-item"
            type="button"
            style={{
              color: 'black',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14.5px',
              paddingLeft: '10px',
            }}
            onClick={() => getEmail(pair.text, pair.url)}
            disabled={isGeneratingChat}
          >
            <img
              src={emailLogo}
              height="28px"
              width="32px"
              style={{ paddingRight: '4px' }}
              alt=""
            />
            Email
          </button>

          <hr className="divider" />
          <div
            className="dropdown-item"
            type="button"
            style={{
              color: 'black',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14.5px',
              paddingLeft: '10px',
            }}
            onClick={() => copyLink(pair.text, pair.url)}
          >
            <img
              src={copyLogo}
              height="26px"
              width="30px"
              style={{ paddingRight: '4px' }}
              alt=""
            />
            Copy Link
          </div>
        </div>
      </div>

    );
}

export default SocialMedia;
