import ReactDOM from "react-dom/client";

import ChatComponent from "./components/Chat";
import { VerificationProvider } from "./context/VerificationContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <VerificationProvider>
    <ChatComponent />
  </VerificationProvider>
);