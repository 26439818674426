import { React, useState, useEffect } from "react";
import Slider from "react-slick";
import SocialMedia from "./SocialMedia";
import dummyImage from "../assets/images/dummy.jpg";
import { ADD_TO_CART, REDIRECT } from "../constants";
import { useVerification } from "../context/VerificationContext";

let origin = window.self === window.top ? document.URL : document.referrer;
origin = origin.substring(0, origin.length - 1);

const atD = " costing $";

function SampleNextArrow(props) {
  const { className, style, onClick, currentSlide, slideCount } = props;
  return (
    <div
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 3 ? " slick-disabled" : "")
      }
      aria-disabled={currentSlide === slideCount - 3 ? true : false}
      style={{
        width: "48px",
        height: "65px",
        borderRadius: "4px",
        boxSizing: "border-box",
        cursor: "pointer",
        display: currentSlide === slideCount - 3 ? "none" : "flex",
        color: "black",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "35%",
        right: "10px",
      }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        fill="currentColor"
        className="bi bi-chevron-right"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, currentSlide } = props;
  return (
    <div
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-disabled={currentSlide === 0 ? true : false}
      style={{
        width: "48px",
        height: "65px",
        borderRadius: "4px",
        boxSizing: "border-box",
        cursor: "pointer",
        zIndex: "2",
        color: "black",
        display: currentSlide === 0 ? "none" : "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "35%",
        left: "10px",
      }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        fill="currentColor"
        className="bi bi-chevron-left"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        />
      </svg>
    </div>
  );
}

function ImageList(props) {
  const {
    message,
    isGeneratingChat,
    sendChatReq,
    setContext,
    prodContext,
    isContextSet,
    add2cart,
    removeContext,
  } = props;

  const { botConfig } = useVerification();
  const cartColor = botConfig?.product_config?.addToCartColor;
  const isShareEnable = botConfig?.product_config?.shareOption;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: false,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
    ],
  };

  return message.products?.length == 0 ? (
    ""
  ) : (
    <div style={{ marginTop: "25px" }}>
      <div style={{ maxWidth: "750px", margin: "auto" }}>
        <Slider {...settings}>
          {message.products?.map((pair, index) => (
            <div
              key={index}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                padding: 20,
                paddingBottom: 0,
                marginBottom: 0,
                paddingTop: 0,
                marginTop: 0,
                minWidth: "33%",
                borderRadius: 20,
                height: "fit-content",
              }}
            >
              <div
                style={{
                  border: "0px solid rgb(244, 244, 247)",
                  height: "fit-content",
                  position: "relative",
                  margin: "7px",
                }}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={pair.url}
                    alt=""
                    onError={(e) => {
                      e.target.src = dummyImage;
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "193px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRadius: "15px",
                    }}
                    onClick={() => {
                      window.parent.postMessage(
                        {
                          id: REDIRECT.id,
                          type: REDIRECT.type,
                          shoppiData: {
                            product: pair,
                            hrefData: {},
                            review: {},
                            search: {},
                          },
                        },
                        origin
                      );
                      setContext(pair.text, atD, pair.price);
                    }}
                  />
                  <div
                    style={{
                      float: "right",
                      background: "transparent",
                      border: "none !important",
                      position: "absolute",
                      right: "10px",
                      bottom: "10px",
                    }}
                  >
                    <button
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      disabled={
                        isContextSet && prodContext.text.indexOf(pair.text) < 0
                          ? true
                          : isGeneratingChat
                      }
                      style={{
                        float: "right",
                        background: "transparent",
                        border: "none",
                      }}
                      className="link"
                    >
                      {prodContext.text.indexOf(pair.text) > -1 ? (
                        <span className="fa-stack customProd" type="button">
                          <i className="fa fa-circle-notch fa-stack-2x"></i>
                          <i
                            style={{ color: "rgb(255, 255, 255)" }}
                            className="fa fa-ellipsis-vertical fa-stack-1x"
                          ></i>
                        </span>
                      ) : isContextSet &&
                        prodContext.text.indexOf(pair.text) < 0 ? (
                        <span
                          className="fa-stack custom no-spotlight"
                          type="button"
                        >
                          <i className="fa fa-circle fa-stack-2x"></i>
                          <i
                            style={{ color: "rgb(255, 255, 255)" }}
                            className="fa fa-ellipsis-vertical fa-stack-1x"
                          ></i>
                        </span>
                      ) : (
                        <span className="fa-stack custom" type="button">
                          <i className="fa fa-circle fa-stack-2x"></i>
                          <i
                            style={{ color: "rgb(255, 255, 255)" }}
                            className="fa fa-ellipsis-vertical fa-stack-1x"
                          ></i>
                        </span>
                      )}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <h6
                        className="dropdown-header"
                        style={{
                          fontFamily: "Inter, sans-serif",
                          fontSize: 13,
                        }}
                      >
                        Options
                      </h6>
                      <button
                        className="dropdown-item"
                        type="button"
                        style={{
                          color: "black",
                          fontFamily: "Inter, sans-serif",
                          fontSize: "14.5px",
                        }}
                        onClick={() =>
                          sendChatReq(
                            ` Can you show me more products related to the ${pair.text}?`
                          )
                        }
                        disabled={isGeneratingChat}
                      >
                        Similar Porducts
                      </button>

                      <button
                        className="dropdown-item"
                        type="button"
                        style={{
                          color: "black",
                          fontFamily: "Inter, sans-serif",
                          fontSize: "14.5px",
                        }}
                        onClick={() => {
                          sendChatReq(
                            `Can you show me a bundle idea for the  ${pair.text}?`
                          );
                        }}
                        disabled={isGeneratingChat}
                      >
                        Bundle Ideas
                      </button>

                      {isContextSet ? (
                        <button
                          className="dropdown-item"
                          type="button"
                          style={{
                            color: "black",
                            fontFamily: "Inter, sans-serif",
                            fontSize: "14.5px",
                          }}
                          onClick={removeContext}
                          disabled={isGeneratingChat}
                        >
                          Remove Spotlight item
                        </button>
                      ) : (
                        <button
                          className="dropdown-item"
                          type="button"
                          style={{
                            color: "black",
                            fontFamily: "Inter, sans-serif",
                            fontSize: "14.5px",
                          }}
                          onClick={() => {
                            setContext(pair.text, atD, pair.price);
                          }}
                          disabled={isGeneratingChat}
                        >
                          Spotlight item
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="product_info"
                  style={{
                    float: "left",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h6
                    className="product_name"
                    style={{ float: "left", height: "40px" }}
                  >
                    {pair.text}
                  </h6>

                  <div
                    className="product_prices"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <br />
                    <br />

                    <div
                      style={{
                        color: "rgb(43, 43, 43)",
                        fontSize: "var(--grouped-icon-font-size)",
                        fontWeight: "600",
                        alignItems: "left",
                      }}
                    >
                      $ {pair.price}
                    </div>
                    {isShareEnable ? (
                      <SocialMedia
                        isGeneratingChat={isGeneratingChat}
                        pair={pair}
                      />
                    ) : (
                      ""
                    )}

                    <div>
                      <button
                        onClick={() => {
                          window.parent.postMessage(
                            {
                              id: ADD_TO_CART.id,
                              type: ADD_TO_CART.type,
                              shoppiData: {
                                product: pair,
                                hrefData: {},
                                review: {},
                                search: {},
                              },
                            },
                            origin
                          );
                          add2cart(pair.text);
                        }}
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: cartColor ? cartColor : "#3c5a96",
                          // backgroundColor: "#b0965600",
                          padding: "5px 7px",
                          borderRadius: "5px",
                          color: "white",
                          fontSize: "1rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-cart3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{ height: "150px" }}></div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ImageList;
